import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import OurApproach from './components/OurApproach';
import Service from './components/Service';
import ContactUs from './components/ContactUs';
import Testimonial from './components/Testimonial';
import PrivacySection from './components/PrivacySection';
import PrivacyVideoPage from './components/PrivacyVideoPage';
import Call from './components/Call';
import Ratings from './components/Ratings';
import Footer from './components/Footer';
import BlogPage from './components/Blogs/BlogPage';
import BlogPostPage from './components/Blogs/BlogPostPage';
import OurMission from './components/AboutUs/OurMission';
import FoundersMessage from './components/AboutUs/FoundersMessage';
import ManagedServer from './components/Solutions/ManagedServer';
import CloudSolutions from './components/Solutions/CloudSolutions';
import TallySolutions from './components/Solutions/TallySolutions';
import BackupServices from './components/Solutions/BackupServices';
import WebServices from './components/Solutions/WebServices';
import ScrollToTop from './components/ScrollToTop';

// Layout component that wraps every page
const Layout = ({ children }) => (
  <>
    {children}
    <Footer />
  </>
);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={
          <Layout>
            <Home />
            <Ratings />
            <OurApproach />
            <Service />
            <Testimonial />
            <PrivacySection />
            <Call />
          </Layout>
        } />
        <Route path="/privacy-video" element={
          <Layout>
            <PrivacyVideoPage />
          </Layout>
        } />
        <Route path="/contact-us" element={
          <Layout>
            <ContactUs />
          </Layout>
        } />
        <Route path="/blog" element={
          <Layout>
            <BlogPage />
          </Layout>
        } />
        <Route path="/blog/:id" element={
          <Layout>
            <BlogPostPage />
          </Layout>
        } />
        <Route path="/about-us/our-mission" element={
          <Layout>
            <OurMission />
          </Layout>
        } />
        <Route path="/about-us/founders-message" element={
          <Layout>
            <FoundersMessage />
          </Layout>
        } />
        <Route path="/solutions/managed-server" element={
          <Layout>
            <ManagedServer />
          </Layout>
        } />
        <Route path="/solutions/cloud-solutions" element={
          <Layout>
            <CloudSolutions />
          </Layout>
        } />
        <Route path="/solutions/tally-solutions" element={
          <Layout>
            <TallySolutions />
          </Layout>
        } />
        <Route path="/solutions/backup-services" element={
          <Layout>
            <BackupServices />
          </Layout>
        } />
        <Route path="/solutions/web-services" element={
          <Layout>
            <WebServices />
          </Layout>
        } />
      </Routes>
    </Router>
  );
}

export default App;