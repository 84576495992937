import React from 'react';
import backgroundVideo from '../assets/video.mp4'; // Adjust this path as needed

function Home() {
  return (
    <section className="relative flex items-center justify-center overflow-hidden md:h-screen h-[100dvh]">
      <video 
        autoPlay 
        loop 
        muted 
        className="absolute inset-0 object-cover w-full h-full"
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="z-10 text-white text-center px-4 max-w-4xl font-['Helvetica','Arial','sans-serif']">
        <h1 className="text-4xl md:text-6xl font-bold mb-4 leading-tight">
          Your Data Our Priority<br />
        </h1>
        <p className="text-lg md:text-xl mb-4">
          Enabling secure transformations with
        </p>
        <h2 className="text-2xl md:text-3xl font-semibold mb-2">
          Web 3.0 & Industry 4.0 solutions
        </h2>
        <p className="text-lg md:text-xl mb-8">
          for enterprises, ready to redefine tomorrow
        </p>
      </div>
    </section>
  );
}

export default Home;