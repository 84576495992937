import React from 'react';

const PrivacyVideoPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 mt-20 sm:mt-0 pt-8 sm:pt-32 pb-8 sm:pb-12 px-3 sm:px-6 lg:px-8 font-helvetica">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl sm:text-4xl font-bold text-center text-gray-900 mb-8 sm:mb-16 leading-tight sm:leading-normal px-2 sm:px-0">
          OUR PRIVACY COMMITMENT
        </h1>
        <div className="relative w-full overflow-hidden rounded-lg shadow-lg">
          <div className="aspect-w-16 aspect-h-9">
            <iframe 
              className="w-full h-full"
              src="https://www.youtube.com/embed/your-privacy-video-id" 
              title="Privacy Policy Video"
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </div>
        </div>
        
        {/* Optional: Add a description section below the video */}
        <div className="mt-6 sm:mt-8 text-center">
          <p className="text-sm sm:text-base text-gray-600 px-3 sm:px-0 leading-relaxed">
            Watch our video to learn more about how we protect your privacy and handle your data with the utmost care and security.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyVideoPage;