import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BlogPostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Fetch the specific blog post data from your backend
    fetch(`/api/blog/${id}`)
      .then(response => response.json())
      .then(data => setPost(data));
  }, [id]);

  if (!post) return <div>Loading...</div>;

  return (
    <div className="w-full">
      <div className="relative h-96 bg-gray-900">
        <img src={post.image} alt={post.title} className="w-full h-full object-cover opacity-50" />
        <div className="absolute inset-0 flex flex-col justify-end p-8">
          <div className="text-white">
            <p className="mb-2">{post.date}</p>
            <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="prose max-w-none">
          <p className="text-lg mb-6">{post.excerpt}</p>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
      </div>
    </div>
  );
};

export default BlogPostPage;