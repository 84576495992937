import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Testimonial = ({ quote, author, position }) => (
  <div className="bg-gradient-to-r from-green-100 to-blue-100 rounded-lg p-6 md:p-12 shadow-lg max-w-6xl mx-auto relative">
    <div className="absolute top-0 left-0 w-12 md:w-16 h-12 md:h-16 bg-green-200 rounded-tl-lg flex items-center justify-center">
      <span className="text-3xl md:text-4xl text-green-600 font-serif">"</span>
    </div>
    <blockquote className="text-center mb-4 md:mb-8 pt-6 md:pt-8">
      <p className="text-base md:text-2xl text-gray-800 leading-relaxed px-2 md:px-0">
        {quote}
      </p>
    </blockquote>
    <div className="text-center">
      <p className="text-blue-600 font-semibold text-lg md:text-xl">{author}</p>
      <p className="text-gray-600 text-base md:text-lg">{position}</p>
    </div>
  </div>
);

const TestimonialSection = () => {
  const testimonials = [
    {
      quote: "We recently shifted to Robnett's cloud services, and it has been a positive change for our company. The transition was smooth, and the team at Robnett took the time to understand our needs. Their cloud solutions are reliable and secure, giving us confidence as we continue to expand our business.",
      author: "Lisa Thompson",
      position: "CTO, BrightTech Solutions"
    },
    {
      quote: "Switching to Tally on Cloud with Robnett was one of the best decisions we’ve made. It has allowed our team to work remotely with ease and improved our collaboration significantly. The setup was straightforward, and the support team was there whenever we needed them. It’s practical and effective for our needs.",
      author: "Rajiv Singh",
      position: "Owner, Patel & Co"
    },
    {
      quote: "Robnett Technologies made integrating Tally ERP into our business much simpler than we anticipated. Their team was helpful and patient, guiding us through the process and ensuring we got the most out of the system. We've noticed better efficiency in our daily operations, and any questions we have are answered promptly.",
      author: "David Chen",
      position: "Director of Operations, TechForward Solutions"
    },
    {
      quote: "We were looking for a dependable backup solution, and Robnett delivered exactly that. The process was straightforward, and they offered solutions that fit our business without overselling. Their backup service has been reliable, and knowing our data is protected is a big relief. It’s one less thing to worry about in a busy workday.",
      author: "Emily Wong",
      position: "IT Administrato, Core Medical Supplies"
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoScrollPaused, setIsAutoScrollPaused] = useState(false);

  const nextTestimonial = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  }, [testimonials.length]);

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  // Auto-scroll functionality
  useEffect(() => {
    let intervalId;

    if (!isAutoScrollPaused) {
      intervalId = setInterval(() => {
        nextTestimonial();
      }, 5000); // 5 seconds interval
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoScrollPaused, nextTestimonial]);

  // Pause auto-scroll on hover
  const handleMouseEnter = () => {
    setIsAutoScrollPaused(true);
  };

  const handleMouseLeave = () => {
    setIsAutoScrollPaused(false);
  };

  return (
    <div 
      className="py-12 md:py-24 px-4 sm:px-6 lg:px-8 bg-[#faf9f6]"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="max-w-7xl mx-auto relative">
        <Testimonial {...testimonials[currentIndex]} />
        
        {/* Navigation Buttons */}
        <div className="flex justify-between mt-4 md:mt-0 md:block">
          <button 
            onClick={prevTestimonial} 
            className="md:absolute md:top-1/2 md:-left-8 md:transform md:-translate-y-1/2 bg-green-800 text-white p-3 md:p-4 rounded-full hover:bg-green-700 transition-colors duration-300"
            aria-label="Previous testimonial"
          >
            <FaChevronLeft size={20} className="md:w-6 md:h-6" />
          </button>
          <button 
            onClick={nextTestimonial} 
            className="md:absolute md:top-1/2 md:-right-8 md:transform md:-translate-y-1/2 bg-green-800 text-white p-3 md:p-4 rounded-full hover:bg-green-700 transition-colors duration-300"
            aria-label="Next testimonial"
          >
            <FaChevronRight size={20} className="md:w-6 md:h-6" />
          </button>
        </div>

        {/* Dots indicator for mobile */}
        <div className="flex justify-center space-x-2 mt-4 md:hidden">
          {testimonials.map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full ${
                index === currentIndex ? 'bg-green-800' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;