import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import $ from 'jquery';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import { FaLinkedin, FaYoutube, FaTwitter, FaFacebook, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FaFileAlt, FaCloudUploadAlt, FaEnvelope, FaSignature, FaCubes, FaLock } from 'react-icons/fa';
import logo from '../../assets/logo2.png';
import heroBackground from '../../assets/tally-solutions2.jpg';
import tallyOnCloudImage from '../../assets/toc.jpg';
import tallyCustomizationsImage from '../../assets/custo.jpg';
import tallyIntegrationsImage from '../../assets/custo2.jpg';
import tallyPrimeServerImage from '../../assets/custo3.jpg';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const ServiceCard = ({ title, image, description }) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-lg mx-auto w-full max-w-sm sm:max-w-none transform transition-all duration-300 hover:scale-105">
    <div className="relative h-48 sm:h-56">
      <img src={image} alt={title} className="w-full h-full object-cover" />
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent">
        <h3 className="text-white text-xl sm:text-2xl font-bold p-4">{title}</h3>
      </div>
    </div>
    <div className="p-4 sm:p-6">
      <p className="text-sm sm:text-base text-gray-700">{description}</p>
    </div>
  </div>
);

const TallySolutions = () => {
  const sectionRef = useRef(null);
  const containerRef = useRef(null);
  const headlineRef = useRef(null);
  const buttonRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const services = [
    {
      title: "Tally on Cloud",
      image: tallyOnCloudImage,
      description: "Enjoy the flexibility of accessing Tally from anywhere, anytime, without the hassle of managing local servers. Robnett's Tally on Cloud ensures seamless remote operations with advanced security measures, so your financial data is always protected."
    },
    {
      title: "Tally Customizations",
      image: tallyCustomizationsImage,
      description: "Tailor your Tally software to fit your business needs with Robnett's customization services. We adapt Tally functionalities to suit industry-specific requirements, enabling faster and more accurate operations aligned with your business objectives."
    },
    {
      title: "Tally Integrations",
      image: tallyIntegrationsImage,
      description: "Robnett streamlines your operations by integrating Tally with other business tools, enhancing workflow automation, and ensuring that data flows smoothly across platforms without compromising on security."
    },
    {
      title: "Tally Prime Server",
      image: tallyPrimeServerImage,
      description: "Get enterprise-level performance with Tally Prime Server. Our solution ensures that your operations are uninterrupted, providing faster and more efficient processing even for complex accounting scenarios."
    }
  ];
  const testimonials = [
    {
      quote: "Robnett's Tally services have truly streamlined our financial operations. The team understood our specific requirements and customized the Tally solution to fit our processes perfectly. Their support throughout implementation and beyond has been exceptional, making our accounting more efficient and error-free",
      author: "Siddharth Mehra ",
      position: "CFO, Zenith Enterprises"
    },
    {
      quote: "Adopting Robnett’s Tally services has been a game-changer for our business. It’s user-friendly, and their team made the integration seamless. We now handle our invoicing and inventory management much faster. The support staff is always there to assist whenever we have any questions, making the whole experience stress-free.",
      author: "Priya Nair",
      position: "Managing Director, GreenLeaf Exports"
    },
    {
      quote: "We needed a reliable partner to implement Tally for our expanding business, and Robnett came highly recommended. The service we received was top-notch, and the customization options have helped us manage our finances more effectively. What sets Robnett apart is their commitment to ongoing support, ensuring we get the most out of Tally",
      author: "Ahmed Khan",
      position: "Owner, Khan Trading Co"
    }
  ];

  const tallyModules = [
    {
      icon: <FaFileAlt className="text-4xl text-blue-500 mb-4" />,
      title: "Documents Management",
      description: "Store, share, track and manage files including history tracking with our computerized network"
    },
    {
      icon: <FaCloudUploadAlt className="text-4xl text-blue-500 mb-4" />,
      title: "Auto backup in cloud",
      description: "Auto backup ensures uninterrupted working"
    },
    {
      icon: <FaEnvelope className="text-4xl text-blue-500 mb-4" />,
      title: "Auto Email Module",
      description: "Auto mail trigger on voucher posting"
    },
    {
      icon: <FaSignature className="text-4xl text-blue-500 mb-4" />,
      title: "Seal & signature to invoice",
      description: "Once it is added, there is no need for further intervention, reduce the chances of errors."
    },
    {
      icon: <FaCubes className="text-4xl text-blue-500 mb-4" />,
      title: "Master Active Inactive Module",
      description: "Enables users to block / deactivate all stock items and Ledger masters that are not required"
    },
    {
      icon: <FaLock className="text-4xl text-blue-500 mb-4" />,
      title: "Audit lock & security module",
      description: "Complete user data authentication, authorization, integrity and confidentiality security framework"
    }
  ];

  useEffect(() => {
    const ctx = gsap.context(() => {
      // Cards animation
      gsap.set(".service-card", { y: 100, opacity: 0 });

      ScrollTrigger.create({
        trigger: sectionRef.current,
        start: "top 80%",
        onEnter: () => {
          gsap.to(".service-card", {
            y: 0,
            opacity: 1,
            duration: 0.5,
            stagger: 0.1,
            ease: "power3.out"
          });
        },
        once: true
      });

      // Call section animations
      const callTl = gsap.timeline();
      
      callTl.fromTo(containerRef.current, 
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power2.out' }
      )
      .fromTo(headlineRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power2.out' }
      )
      .to(headlineRef.current, {
        duration: 2,
        text: "Your Trusted Partner.",
        ease: "none",
      })
      .fromTo(buttonRef.current,
        { opacity: 0, x: 20 },
        { opacity: 1, x: 0, duration: 0.4, ease: 'power2.out' },
        '-=0.3'
      );

      // Button animations
      const buttonZoom = gsap.timeline({ repeat: -1, yoyo: true });
      buttonZoom.to(buttonRef.current, {
        scale: 1.05,
        duration: 0.5,
        ease: 'power1.inOut'
      });

      $(buttonRef.current).hover(
        function() { 
          gsap.to(this, { backgroundColor: '#333', duration: 0.3 });
          buttonZoom.pause();
          gsap.to(this, { scale: 1.1, duration: 0.3 });
        },
        function() { 
          gsap.to(this, { backgroundColor: '#000', duration: 0.3 });
          buttonZoom.resume();
          gsap.to(this, { scale: 1, duration: 0.3 });
        }
      );
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };
  return (
    <>
      {/* Hero Section */}
      <div className="relative min-h-[80vh] md:min-h-screen flex items-center text-white font-helvetica">
        <div className="absolute inset-0 z-0">
          <img src={heroBackground} alt="Tally Solutions Background" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="relative z-10 px-4 sm:px-10 max-w-4xl mx-auto md:ml-16 lg:ml-24 mt-16 md:mt-24">
          <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight">
            Revolutionizing Business Operations with Cloud
          </h1>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-relaxed">
            Accounting Solutions
          </h2>
        </div>
      </div>

      <div ref={sectionRef} className="w-full font-helvetica bg-[#faf9f6]">
        {/* Mission Statement Section */}
        <div className="bg-[#faf9f6] pt-8 sm:pt-16 pb-8 sm:pb-20">
          <div className="max-w-full mx-auto px-4 sm:px-8 lg:px-16">
            <div className="text-left sm:text-left">
            <h1 className="text-2xl sm:text-3xl md:text-5xl font-bold mb-3 md:mb-6 font-helvetica">
                Empowering Businesses with Robnett's <span className="text-green-900">Tally</span> Solutions
              </h1>
              <p className="text-base sm:text-xl md:text-2xl leading-relaxed text-gray-700 max-w-full mt-4 sm:mt-6">
                At Robnett Technologies, we provide cutting-edge Tally solutions tailored to meet the diverse needs of businesses. Our Tally services ensure seamless financial management, enhanced productivity, and robust data security, allowing you to focus on growing your business.
              </p>
            </div>
          </div>
        </div>

        {/* Tally Solutions Cards */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mb-12 sm:mb-20">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>

        {/* Bonus Modules Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mb-12 sm:mb-20">
          <h2 className="text-2xl sm:text-4xl font-bold text-center mb-3 sm:mb-4">
            Elevate Your TallyPrime Experience with <span className="text-green-900">BONUS</span> Modules
          </h2>
          <p className="text-base sm:text-xl text-center text-gray-600 mb-8 sm:mb-12">
            Choose from our 100+ modules
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {tallyModules.map((module, index) => (
              <div key={index} className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                <div className="flex flex-col items-center sm:items-start">
                  {React.cloneElement(module.icon, {
                    className: "text-3xl sm:text-4xl text-blue-500 mb-3 sm:mb-4"
                  })}
                  <h3 className="text-lg sm:text-xl font-semibold mb-2 text-center sm:text-left">{module.title}</h3>
                  <p className="text-sm sm:text-base text-gray-600 text-center sm:text-left">{module.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-8 sm:mt-12">
            <Link
              to="/contact-us"
              className="bg-green-900 text-white hover:bg-green-800 px-6 sm:px-8 py-2 sm:py-3 rounded-full font-bold text-base sm:text-lg transition duration-300 inline-block"
            >
              See more
            </Link>
          </div>
        </div>

        {/* Testimonial Section */}
        {/* Testimonial Section */}
<div className="py-16 sm:py-24 px-4 sm:px-6 lg:px-8 bg-[#faf9f6]">
  <div className="max-w-7xl mx-auto relative">
    <div className="bg-gradient-to-r from-green-100 to-blue-100 rounded-lg p-6 sm:p-10 shadow-lg max-w-6xl mx-auto relative -mt-8 sm:-mt-16 md:-mt-20 lg:-mt-24">
      <div className="absolute top-0 left-0 w-12 sm:w-16 h-12 sm:h-16 bg-green-200 rounded-tl-lg flex items-center justify-center">
        <span className="text-3xl sm:text-4xl text-green-600 font-serif">"</span>
      </div>
      <blockquote className="text-center mb-4 sm:mb-8 pt-6 sm:pt-8">
        <p className="text-base sm:text-2xl text-gray-800 leading-relaxed px-4 sm:px-0">
          {testimonials[currentIndex].quote}
        </p>
      </blockquote>
      <div className="text-center">
        <p className="text-blue-600 font-semibold text-lg sm:text-xl">{testimonials[currentIndex].author}</p>
        <p className="text-gray-600 text-base sm:text-lg">{testimonials[currentIndex].position}</p>
      </div>
    </div>
    
    {/* Navigation Buttons */}
    <div className="flex justify-between mt-4 sm:mt-0 sm:block">
      <button 
        onClick={prevTestimonial} 
        className="sm:absolute sm:top-1/2 -left-4 sm:-left-8 transform -translate-y-1/2 bg-green-800 text-white p-3 sm:p-4 rounded-full hover:bg-green-700 transition-colors duration-300"
        aria-label="Previous testimonial"
      >
        <FaChevronLeft className="w-4 h-4 sm:w-6 sm:h-6" />
      </button>
      <button 
        onClick={nextTestimonial} 
        className="sm:absolute sm:top-1/2 -right-4 sm:-right-8 transform -translate-y-1/2 bg-green-800 text-white p-3 sm:p-4 rounded-full hover:bg-green-700 transition-colors duration-300"
        aria-label="Next testimonial"
      >
        <FaChevronRight className="w-4 h-4 sm:w-6 sm:h-6" />
      </button>
    </div>

    {/* Dots indicator for mobile only */}
    <div className="flex justify-center space-x-2 mt-4 sm:hidden">
      {testimonials.map((_, index) => (
        <div
          key={index}
          className={`h-2 w-2 rounded-full ${
            index === currentIndex ? 'bg-green-800' : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  </div>
</div>
     

        {/* Call Section */}
        <div ref={containerRef} className="px-4 sm:px-6 py-6 sm:py-8 bg-[#faf9f6] max-w-7xl mx-auto mb-8 sm:mb-0">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-6">
            <div className="text-center sm:text-left">
              <h2 className="text-xl sm:text-3xl font-bold leading-tight text-gray-800 mb-4">
                <div className="mb-2">OPTIMIZE YOUR FINANCIAL MANAGEMENT</div>
                <div>UPGRADE TO ROBNETT'S TALLY SOLUTIONS TODAY!</div>
              </h2>
              <h1 ref={headlineRef} className="text-2xl sm:text-4xl font-bold text-[#14532D] mt-2"></h1>
            </div>
            <Link
              ref={buttonRef}
              to="/contact-us"
              className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-bold text-sm sm:text-base transition-colors duration-300 flex items-center shadow-md hover:shadow-lg whitespace-nowrap"
            >
              TALK TO OUR EXPERTS
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-4 sm:w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Footer */}
 
    </>
  );
};

export default TallySolutions;