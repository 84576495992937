import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import $ from 'jquery';
import { FaLinkedin, FaYoutube, FaTwitter, FaFacebook } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import logo from '../../assets/logo2.png';
import heroBackground from '../../assets/managed-server.jpg';
import dedicatedServersImage from '../../assets/dedicated-server.jpg';
import colocationServicesImage from '../../assets/colocation-pin.jpg';
import supportImage from '../../assets/24hours.jpg';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const StatCard = ({ value, label, suffix = "" }) => (
  <div className="bg-gradient-to-br from-blue-500 to-green-500 rounded-lg shadow-lg p-4 sm:p-6 text-center transform transition-all duration-300 hover:scale-105">
    <h2 className="text-3xl sm:text-5xl font-bold text-[#E6F7FF] mb-2 font-helvetica">
      <span>{value}</span>{suffix}
    </h2>
    <p className="text-base sm:text-lg text-[#E6F7FF] font-helvetica">{label}</p>
  </div>
);

const rotateIn = keyframes`
  0% { transform: rotateX(90deg); }
  100% { transform: rotateX(0deg); }
`;

const rotateOut = keyframes`
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(-90deg); }
`;

const RotatingTextWrapper = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  @media (min-width: 640px) {
    font-size: 36px;
  }
  color: #333;

  p {
    display: inline-flex;
    margin: 0;
    vertical-align: top;
  }
`;

const Word = styled.span`
  position: center;
  padding: 20px;
  display: flex;
  opacity: 0;

  .letter {
    transform-origin: center center 25px;

    &.out {
      transform: rotateX(90deg);
      transition: 0.26s cubic-bezier(0.6, 0, 0.7, 0.2);
    }
    &.in {
      transition: 0.20s ease;
    }
    &.behind {
      transform: rotateX(-90deg);
    }
  }

  &.alizarin { color: #14532D; }
  &.wisteria { color: #3498db; }
  &.peter-river { color: #14532D; }
`;
const RotatingText = ({ words }) => {
  const wordsRef = useRef([]);

  useEffect(() => {
    let currentWordIndex = 0;
    const maxWordIndex = words.length - 1;

    const rotateText = () => {
      const currentWord = wordsRef.current[currentWordIndex];
      const nextWord = currentWordIndex === maxWordIndex ? wordsRef.current[0] : wordsRef.current[currentWordIndex + 1];

      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = "letter out";
        }, i * 80);
      });

      nextWord.style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = "letter behind";
        setTimeout(() => {
          letter.className = "letter in";
        }, 340 + i * 80);
      });

      currentWordIndex = currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    wordsRef.current[0].style.opacity = "1";
    rotateText();
    const intervalId = setInterval(rotateText, 4000);

    return () => clearInterval(intervalId);
  }, [words]);

  return (
    <RotatingTextWrapper>
      <p>
        {words.map((word, index) => (
          <Word
            key={index}
            ref={el => wordsRef.current[index] = el}
            className={`word ${word.color}`}
          >
            {word.text.split('').map((letter, i) => (
              <span key={i} className="letter">{letter}</span>
            ))}
          </Word>
        ))}
      </p>
    </RotatingTextWrapper>
  );
};

const ServiceCard = ({ title, image, description }) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-lg mx-2 flex flex-col font-helvetica">
    <div className="relative h-40 sm:h-48">
      <img src={image} alt={title} className="w-full h-full object-cover" />
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent">
        <h3 className="text-white text-xl sm:text-2xl font-bold p-4">
          {title}
        </h3>
      </div>
    </div>
    <div className="p-4 flex-grow">
      <p className="text-sm sm:text-base text-gray-700">{description}</p>
    </div>
  </div>
);

const ManagedServer = () => {
  const sectionRef = useRef(null);
  const iconRef = useRef(null);
  const containerRef = useRef(null);
  const headlineRef = useRef(null);
  const buttonRef = useRef(null);

  const rotatingWords = [
    { text: "Security", color: "alizarin" },
    { text: "Performance", color: "wisteria" },

  ];

  const services = [
    {
      title: "Dedicated Servers",
      image: dedicatedServersImage,
      description: "High-performance dedicated servers tailored to your specific needs with full control and customization options."
    },
    {
      title: "Colocation Services",
      image: colocationServicesImage,
      description: "Secure facility for your hardware with redundant power, cooling, and network connectivity."
    },
    {
      title: "24/7 Support",
      image: supportImage,
      description: "Round-the-clock technical support and monitoring to ensure your servers run smoothly."
    }
  ];

  useEffect(() => {
    const tl = gsap.timeline();
    
    tl.fromTo(containerRef.current, 
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: 'power2.out' }
    )
    .fromTo(headlineRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: 'power2.out' }
    )
    .to(headlineRef.current, {
      duration: 2,
      text: "Your Trusted Partner.",
      ease: "none",
    })
    .fromTo(buttonRef.current,
      { opacity: 0, x: 20 },
      { opacity: 1, x: 0, duration: 0.4, ease: 'power2.out' },
      '-=0.3'
    );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <>
      {/* Hero Section */}
      <div className="relative min-h-[80vh] md:min-h-screen flex items-center text-white font-helvetica">
  <div className="absolute inset-0 z-0">
    <img 
      src={heroBackground} 
      alt="Server Background" 
      className="w-full h-full object-cover object-center" 
    />
    <div className="absolute inset-0 bg-black opacity-50"></div>
  </div>
  <div className="relative z-10 px-4 sm:px-10 max-w-4xl mx-auto md:ml-16 lg:ml-24 mt-16 md:mt-24">
    <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight">
      Empowering Businesses with Reliable
    </h1>
    <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-relaxed">
      Managed Servers
    </h2>
  </div>
</div>

      <div ref={sectionRef} className="w-full font-helvetica bg-[#faf9f6]">
        {/* Mission Statement Section */}
        <div className="bg-[#faf9f6] pt-8 sm:pt-16 pb-12 sm:pb-20">
          <div className="max-w-full mx-auto px-4 sm:px-8 lg:px-16">
            <div className="text-left">
              <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
                Seamless Server Management for a <span className="text-green-900">Secure</span> and Scalable Future
              </h1>
              <p className="text-base sm:text-xl md:text-2xl leading-relaxed text-gray-700 max-w-full">
                In today's digital landscape, businesses require more than just a server; they need a secure, scalable, and efficient solution to support their operations. At Robnett Technologies, our Managed Server Services are designed to take the burden off your IT team, allowing you to focus on what you do best while we handle the complexities of server management.
              </p>
            </div>
          </div>
        </div>

        {/* Core Services Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mb-12 sm:mb-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>

        {/* Dynamic Headline Section */}
        <div className="max-w-6xl mx-auto text-center mb-12 sm:mb-20 px-4">
          <h2 className="text-2xl sm:text-4xl font-bold mb-4">
            Robnett's Managed servers: Built for{' '}
            <RotatingText words={rotatingWords} />
          </h2>
        </div>

        {/* Ratings Section */}
        <div className="bg-[#faf9f6] p-4 sm:p-8 overflow-hidden mb-12 sm:mb-20 -mt-8 sm:-mt-16">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
              <StatCard value={99.9} label="Uptime" suffix="%" />
              <StatCard value={24} label="Support" suffix="/7" />
              <StatCard value={100} label="Data Security" suffix="%" />
              <StatCard value={30} label="Response Time" suffix=" min" />
            </div>
          </div>
        </div>

        {/* Call Section */}
        <div ref={containerRef} className="px-4 sm:px-6 py-8 md:py-6 bg-[#faf9f6] max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6 md:gap-8">
            <div className="flex flex-col text-center md:text-left">
              <h2 className="text-xl md:text-3xl font-bold leading-tight text-gray-800 font-helvetica mb-3 md:mb-4">
                <div className="mb-2 md:mb-1">TAKE CONTROL OF YOUR SECURE TRANSFORMATION</div>
                <div>SCALE YOUR BUSINESS WITH ROBNETT TECHNOLOGIES</div>
              </h2>
              <h1 
                ref={headlineRef} 
                className="text-2xl md:text-4xl font-bold text-green-900 font-helvetica mt-1 md:mt-2"
              ></h1>
            </div>
            <Link
              ref={buttonRef}
              to="/contact-us"
              className="bg-black text-white px-4 md:px-6 py-2 md:py-3 rounded-full font-bold text-sm md:text-base transition-colors duration-300 flex items-center shadow-md hover:shadow-lg font-helvetica whitespace-nowrap"
            >
              TALK TO OUR EXPERTS
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-3 w-3 md:h-4 md:w-4 ml-2" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                  clipRule="evenodd" 
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Footer */}
      
    </>
  );
};

export default ManagedServer;

