import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';
import { RiShieldKeyholeLine } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';

const PrivacySection = () => {
  const iconRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      iconRef.current,
      { 
        rotationY: 0,
        opacity: 0,
        scale: 0.5
      },
      { 
        rotationY: 360,
        opacity: 1,
        scale: 1,
        duration: 1.5,
        ease: "back.out(1.7)"
      }
    );

    // Add hover effect
    const icon = iconRef.current;
    const hoverAnimation = gsap.to(icon, {
      scale: 1.1,
      duration: 0.3,
      paused: true
    });

    icon.addEventListener('mouseenter', () => hoverAnimation.play());
    icon.addEventListener('mouseleave', () => hoverAnimation.reverse());

    return () => {
      icon.removeEventListener('mouseenter', () => hoverAnimation.play());
      icon.removeEventListener('mouseleave', () => hoverAnimation.reverse());
    };
  }, []);

  return (
    <div className="bg-[#faf9f6] py-8 md:py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
        <div ref={iconRef} className="cursor-pointer">
          <RiShieldKeyholeLine className="mx-auto h-14 md:h-20 w-14 md:w-20 text-black mb-4 md:mb-6" />
        </div>
        <h2 className="text-2xl md:text-4xl font-bold leading-tight text-gray-800 font-montserrat mb-3 md:mb-4 px-2 md:px-0">
          Your Data is our Responsibility
        </h2>
        <p className="text-base md:text-xl text-gray-600 mb-6 md:mb-8 px-3 md:px-0 leading-relaxed">
          At Robnett Technologies, we put your data's safety at the forefront of everything we do. Our mission is to redefine the standards of data protection, making sure that your trust in us is always rewarded with uncompromising commitment to privacy and security.
        </p>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-4 md:px-0"
        >
          <Link 
            to="/privacy-video"
            className="inline-flex items-center px-4 md:px-6 py-2 md:py-3 border border-transparent text-sm md:text-base font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out"
          >
            <FaPlay className="mr-2 h-3 w-3 md:h-4 md:w-4" /> WATCH VIDEO
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default PrivacySection;