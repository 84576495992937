import React from 'react';
import { motion } from 'framer-motion';

const FoundersMessage = () => {
  const text = "Coming Soon";

  const letterVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="text-center">
        <motion.h2 
          className="text-3xl sm:text-5xl font-bold text-gray-800 mb-6 sm:mb-8"
          style={{ fontFamily: "Helvetica, Arial, sans-serif" }}
        >
          {text.split('').map((char, index) => (
            <motion.span
              key={`${char}-${index}`}
              variants={letterVariants}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.5,
                delay: index * 0.1,
                type: "spring",
                stiffness: 120,
                damping: 10
              }}
            >
              {char}
            </motion.span>
          ))}
        </motion.h2>
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          className="w-12 h-12 sm:w-16 sm:h-16 border-t-4 border-blue-500 border-solid rounded-full mx-auto"
        />
      </div>
    </section>
  );
};

export default FoundersMessage;