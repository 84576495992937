import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const Ratings = () => {
  const cardsRef = useRef([]);
  const sectionRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top 80%",
      }
    });

    cardsRef.current.forEach((card, index) => {
      gsap.set(card, { opacity: 0, y: -50 });

      tl.to(card, {
        opacity: 1,
        y: 0,
        duration: 0.2, // Faster animation
        ease: "bounce.out",
        onComplete: () => startCounting(index)
      });

      if (index < cardsRef.current.length - 1) {
        tl.to(card, {
          x: "101%",
          duration: 0.15, // Faster animation
          ease: "power2.in",
          delay: 1 // Shorter delay
        });
        tl.to(card, {
          x: "0%",
          duration: 0.15, // Faster animation
          ease: "power2.out"
        }, ">");
      }

      tl.to({}, { duration: 0.03 }); // Shorter pause
    });

  }, []);

  const startCounting = (index) => {
    const targets = cardsRef.current[index].querySelectorAll('.count-up');
    targets.forEach(target => {
      const value = parseInt(target.getAttribute('data-value'));
      gsap.fromTo(target, 
        { innerText: 0 },
        {
          innerText: value,
          duration: 1, // Faster counting
          ease: "power1.inOut",
          snap: { innerText: 1 },
          stagger: 0.3, // Faster stagger
        }
      );
    });
  };

  return (
    <div ref={sectionRef} className="bg-[#faf9f6] p-4 md:p-8 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 mb-8 md:mb-16">
          <StatCard ref={el => cardsRef.current[0] = el} value={100} label="Customers served" />
          <StatCard ref={el => cardsRef.current[1] = el} value={2500} label="Data secured (terabytes)" />
          <StatCard ref={el => cardsRef.current[2] = el} value={200} label="Security assessments conducted" />
          <StatCard ref={el => cardsRef.current[3] = el} value={100} label="Client satisfaction rate" suffix="%" />
        </div>
      </div>
    </div>
  );
};

const StatCard = React.forwardRef(({ value, label, suffix = "+" }, ref) => (
  <div 
    ref={ref} 
    className="bg-gradient-to-br from-blue-500 to-green-500 rounded-lg shadow-lg p-4 md:p-6 text-center transform transition-all duration-300 hover:scale-105"
  >
    <h2 className="text-3xl md:text-5xl font-bold text-[#E6F7FF] mb-1 md:mb-2 font-Helvetica">
      <span className="count-up" data-value={value}>0</span>{suffix}
    </h2>
    <p className="text-base md:text-lg text-[#E6F7FF] font-Helvetica">{label}</p>
  </div>
));

export default Ratings;