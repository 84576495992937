import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { Link } from 'react-router-dom';
import aiControlImage from '../assets/demo.jpg';
import styled from 'styled-components';

gsap.registerPlugin(TextPlugin);

const RotatingTextWrapper = styled.div`
  display: inline-flex;
  position: relative;
  text-align: left;
  height: 45px;
  @media (min-width: 768px) {
    height: 55px;
  }
  vertical-align: baseline;
  margin-left: 10px;
  font-family: Helvetica, Arial, sans-serif;
`;

const Word = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  font-family: Helvetica, Arial, sans-serif;

  .letter {
    transform-origin: center center 25px;

    &.out {
      transform: rotateX(90deg);
      transition: 0.26s cubic-bezier(0.6, 0, 0.7, 0.2);
    }
    &.in {
      transition: 0.20s ease;
    }
    &.behind {
      transform: rotateX(-90deg);
    }
  }

  &.alizarin { color: #3498db; }
  &.wisteria { color: #3498db; }
  &.peter-river { color: #3498db; }
`;

const RotatingText = ({ words }) => {
  const wordsRef = useRef([]);

  useEffect(() => {
    let currentWordIndex = 0;
    const maxWordIndex = words.length - 1;

    const rotateText = () => {
      const currentWord = wordsRef.current[currentWordIndex];
      const nextWord = currentWordIndex === maxWordIndex ? wordsRef.current[0] : wordsRef.current[currentWordIndex + 1];

      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = "letter out";
        }, i * 80);
      });

      nextWord.style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = "letter behind";
        setTimeout(() => {
          letter.className = "letter in";
        }, 340 + i * 80);
      });

      currentWordIndex = currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    wordsRef.current[0].style.opacity = "1";
    rotateText();
    const intervalId = setInterval(rotateText, 4000);

    return () => clearInterval(intervalId);
  }, [words]);

  return (
    <RotatingTextWrapper>
      {words.map((word, index) => (
        <Word
          key={index}
          ref={el => wordsRef.current[index] = el}
          className={`word ${word.color}`}
        >
          {word.text.split('').map((letter, i) => (
            <span key={i} className="letter">{letter}</span>
          ))}
        </Word>
      ))}
    </RotatingTextWrapper>
  );
};

const OurApproach = () => {
  const rotatingWords = [
    { text: "TRUST", color: "alizarin" },
    { text: "TRANSPARENCY", color: "wisteria" },
    { text: "TEAMWORK", color: "peter-river" },
  ];

  return (
    <div className="relative font-['Helvetica','Arial','sans-serif']">
      <div 
        className="relative bg-cover bg-center min-h-[400px] md:min-h-[600px] h-auto py-20 md:py-40"
        style={{ 
          backgroundImage: `url(${aiControlImage})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-blue-1000 to-transparent"></div>
        <div className="relative z-10 max-w-xl mx-4 md:ml-16 pt-8 md:pt-14">
          <h1 className="text-3xl md:text-4xl font-bold text-white mb-2 text-left">
            Empowering Your Business Journey
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-2 text-left">
            With
          </h2>
          <h2 
            className="text-4xl md:text-5xl font-extrabold text-white mb-4 text-left tracking-wide"
            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}
          >
            <RotatingText words={rotatingWords} />
          </h2>
          <p className="text-base md:text-lg text-white mb-6 text-left">
            Elevate your Business ambitions – cost-effectively – with Robnett Technologies.
          </p>
          <div className="text-left">
            <Link
              to="/about-us/our-mission"
              className="inline-block bg-white text-green-900 font-bold py-2 px-4 rounded-full hover:bg-green-100 transition duration-300 text-sm md:text-base"
            >
              LEARN MORE →
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurApproach;