import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaYoutube, FaTwitter, FaFacebook } from 'react-icons/fa';
import logo from '../assets/logo2.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  // Updated routes to exactly match App.js structure
  const routes = {
    about: {
      mission: "/about-us/our-mission",
      founder: "/about-us/founders-message",
      blogs: "/blog"
    },
    solutions: {
      managed: "/solutions/managed-server",
      cloud: "/solutions/cloud-solutions",
      tally: "/solutions/tally-solutions",
      backup: "/solutions/backup-services",
      web: "/solutions/web-services"
    },
    contact: {
      main: "/contact-us",
      email: "support@robnett.co.in",
      phone: "7982-695-853"
    }
  };

  const socialLinks = {
    linkedin: "https://www.linkedin.com/company/robnett-technologies",
    youtube: "https://www.youtube.com/@RobnettTechnologies",
    twitter: "https://twitter.com/RobnettTech",
    facebook: "https://www.facebook.com/RobnettTechnologies"
  };

  return (
    <footer className="bg-[#faf9f6] border-t border-gray-200 pt-8">
      <div className="container mx-auto px-6">
        {/* ====================== MOBILE VIEW ====================== */}
        <div className="block md:hidden">
          {/* Mobile Logo */}
          <div className="flex justify-center mb-8">
            <img 
              src={logo} 
              alt="Robnett Technologies Logo" 
              className="h-20"
            />
          </div>

          {/* Mobile Social Links */}
          <div className="flex justify-center mb-8">
            <div className="flex space-x-6">
              <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer" className="text-[#0077B5] hover:text-[#0077B5]/80 transition-colors">
                <FaLinkedin size={24} />
              </a>
              <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer" className="text-[#FF0000] hover:text-[#FF0000]/80 transition-colors">
                <FaYoutube size={24} />
              </a>
              <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="text-[#1DA1F2] hover:text-[#1DA1F2]/80 transition-colors">
                <FaTwitter size={24} />
              </a>
              <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" className="text-[#4267B2] hover:text-[#4267B2]/80 transition-colors">
                <FaFacebook size={24} />
              </a>
            </div>
          </div>

          {/* Mobile Navigation */}
          <div className="grid grid-cols-1 gap-8">
            {/* About Us */}
            <div className="text-left">
              <h3 className="font-bold text-lg mb-4">About Us</h3>
              <ul className="space-y-3">
                <li><Link to={routes.about.mission} className="text-gray-600 hover:text-gray-800">Our mission</Link></li>
                <li><Link to={routes.about.founder} className="text-gray-600 hover:text-gray-800">Founder's message</Link></li>
                <li><Link to={routes.about.blogs} className="text-gray-600 hover:text-gray-800">Blogs</Link></li>
              </ul>
            </div>

            {/* Solutions */}
            <div className="text-left">
              <h3 className="font-bold text-lg mb-4">Solutions</h3>
              <ul className="space-y-3">
                <li><Link to={routes.solutions.managed} className="text-gray-600 hover:text-gray-800">Managed servers</Link></li>
                <li><Link to={routes.solutions.cloud} className="text-gray-600 hover:text-gray-800">Cloud solutions</Link></li>
                <li><Link to={routes.solutions.tally} className="text-gray-600 hover:text-gray-800">Tally solutions</Link></li>
                <li><Link to={routes.solutions.backup} className="text-gray-600 hover:text-gray-800">Backup services</Link></li>
                <li><Link to={routes.solutions.web} className="text-gray-600 hover:text-gray-800">Web services</Link></li>
              </ul>
            </div>

            {/* Contact */}
            <div className="text-left">
              <h3 className="font-bold text-lg mb-4">Contact Us</h3>
              <ul className="space-y-3">
                <li><Link to={routes.contact.main} className="text-gray-600 hover:text-gray-800">Contact Us</Link></li>
                <li><a href={`mailto:${routes.contact.email}`} className="text-gray-600 hover:text-gray-800">{routes.contact.email}</a></li>
                <li><a href={`tel:${routes.contact.phone}`} className="text-gray-600 hover:text-gray-800">{routes.contact.phone}</a></li>
              </ul>
            </div>
          </div>
        </div>

        {/* ====================== DESKTOP VIEW ====================== */}
        <div className="hidden md:block">
          <div className="flex flex-row items-start justify-between">
            {/* Logo */}
            <div className="w-1/5">
              <img 
                src={logo} 
                alt="Robnett Technologies Logo" 
                className="h-20"
              />
            </div>

            {/* Your Trusted Partner */}
            <div className="w-1/5">
              <h3 className="font-bold text-xl mb-4">Your Trusted Partner</h3>
              <div className="flex space-x-4">
                <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer" className="text-[#0077B5]">
                  <FaLinkedin size={20} />
                </a>
                <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer" className="text-[#FF0000]">
                  <FaYoutube size={20} />
                </a>
                <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="text-[#1DA1F2]">
                  <FaTwitter size={20} />
                </a>
                <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" className="text-[#4267B2]">
                  <FaFacebook size={20} />
                </a>
              </div>
            </div>

            {/* About Us */}
            <div className="w-1/5">
              <h3 className="font-bold text-xl mb-4">About Us</h3>
              <ul className="space-y-2">
                <li><Link to={routes.about.mission} className="text-gray-500">Our mission</Link></li>
                <li><Link to={routes.about.founder} className="text-gray-500">Founder's message</Link></li>
                <li><Link to={routes.about.blogs} className="text-gray-500">Blogs</Link></li>
              </ul>
            </div>

            {/* Solutions */}
            <div className="w-1/5">
              <h3 className="font-bold text-xl mb-4">Solutions</h3>
              <ul className="space-y-2">
                <li><Link to={routes.solutions.managed} className="text-gray-500">Managed servers</Link></li>
                <li><Link to={routes.solutions.cloud} className="text-gray-500">Cloud solutions</Link></li>
                <li><Link to={routes.solutions.tally} className="text-gray-500">Tally solutions</Link></li>
                <li><Link to={routes.solutions.backup} className="text-gray-500">Backup services</Link></li>
                <li><Link to={routes.solutions.web} className="text-gray-500">Web services</Link></li>
              </ul>
            </div>

            {/* Contact Us */}
            <div className="w-1/5">
              <h3 className="font-bold text-xl mb-4">Contact Us</h3>
              <ul className="space-y-2">
                <li><Link to={routes.contact.main} className="text-gray-500">Contact Us</Link></li>
                <li><a href={`mailto:${routes.contact.email}`} className="text-gray-500">{routes.contact.email}</a></li>
                <li><a href={`tel:${routes.contact.phone}`} className="text-gray-500">{routes.contact.phone}</a></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="border-t border-gray-200 mt-8 py-6">
          <div className="text-sm text-gray-600 text-center md:text-left">
            © {currentYear} Robnett Technologies. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;