import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay, FaLinkedin, FaYoutube, FaTwitter, FaFacebook } from 'react-icons/fa';
import { RiShieldKeyholeLine } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import $ from 'jquery';
import logo from '../../assets/logo2.png';
import heroBackground from '../../assets/mission.jpg';
import customWebsiteImage from '../../assets/our-mission2.jpg';
import ecommercePlatformImage from '../../assets/our-mission3.jpg';
import cmsImage from '../../assets/our-mission.jpg';

gsap.registerPlugin(TextPlugin);

const ServiceCard = ({ title, image, description }) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-lg mx-2 mb-4 flex flex-col">
    <div className="relative h-48 sm:h-56 md:h-48">
      <img src={image} alt={title} className="w-full h-full object-cover" />
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent">
        <h3 className="text-white text-xl md:text-2xl font-bold p-4 font-helvetica">
          {title}
        </h3>
      </div>
    </div>
    <div className="p-4 flex-grow">
      <p className="text-gray-700 font-helvetica text-sm md:text-base">{description}</p>
    </div>
  </div>
);

const OurMission = () => {
  const sectionRef = useRef(null);
  const iconRef = useRef(null);
  const containerRef = useRef(null);
  const headlineRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      iconRef.current,
      { rotationY: 0, opacity: 0, scale: 0.5 },
      { rotationY: 360, opacity: 1, scale: 1, duration: 1.5, ease: "back.out(1.7)" }
    );

    const icon = iconRef.current;
    const hoverAnimation = gsap.to(icon, {
      scale: 1.1,
      duration: 0.3,
      paused: true
    });

    icon.addEventListener('mouseenter', () => hoverAnimation.play());
    icon.addEventListener('mouseleave', () => hoverAnimation.reverse());

    const tl = gsap.timeline();
    
    tl.fromTo(containerRef.current, 
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: 'power2.out' }
    )
    .fromTo(headlineRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: 'power2.out' }
    )
    .to(headlineRef.current, {
      duration: 2,
      text: "Your Trusted Partner.",
      ease: "none",
    })
    .fromTo(buttonRef.current,
      { opacity: 0, x: 20 },
      { opacity: 1, x: 0, duration: 0.4, ease: 'power2.out' },
      '-=0.3'
    );

    const buttonZoom = gsap.timeline({ repeat: -1, yoyo: true });
    buttonZoom.to(buttonRef.current, {
      scale: 1.05,
      duration: 0.5,
      ease: 'power1.inOut'
    });

    $(buttonRef.current).hover(
      function() { 
        gsap.to(this, { backgroundColor: '#333', duration: 0.3 });
        buttonZoom.pause();
        gsap.to(this, { scale: 1.1, duration: 0.3 });
      },
      function() { 
        gsap.to(this, { backgroundColor: '#000', duration: 0.3 });
        buttonZoom.resume();
        gsap.to(this, { scale: 1, duration: 0.3 });
      }
    );

    return () => {
      icon.removeEventListener('mouseenter', () => hoverAnimation.play());
      icon.removeEventListener('mouseleave', () => hoverAnimation.reverse());
      buttonZoom.kill();
    };
  }, []);

  const services = [
    {
      title: 'SECURITY',
      image: customWebsiteImage,
      description: "Protecting your data and ensuring privacy are at the heart of everything we do. Our security protocols are robust, and our solutions are built to safeguard the digital foundations of businesses."
    },
    {
      title: 'INNOVATION',
      image: ecommercePlatformImage,
      description: "As technology evolves, so do we. We continually explore new avenues to bring the most advanced and secure solutions to our clients."
    },
    {
      title: 'CUSTOMER_CENTRICITY',
      image: cmsImage,
      description: "Your needs drive our solutions. At Robnett, we are committed to understanding your business and providing tailored technology that addresses your specific challenges."
    }
  ];
  return (
    <>
      {/* Hero Section */}
      <div className="relative min-h-[80vh] md:min-h-screen flex items-center text-white font-helvetica">
        <div className="absolute inset-0 z-0">
          <img 
            src={heroBackground} 
            alt="AI and ML Background" 
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="relative z-10 px-4 sm:px-6 md:px-10 max-w-4xl mx-auto md:ml-16 lg:ml-24 mt-20 md:mt-24">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 leading-tight font-helvetica">
            Robnett: Pioneering Secure Innovation for
          </h1>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-relaxed font-helvetica">
            a Safer Digital Future
          </h2>
        </div>
      </div>

      <div ref={sectionRef} className="w-full font-helvetica bg-[#faf9f6]">
        {/* Mission Statement Section */}
        <div className="bg-[#faf9f6] pt-8 md:pt-16 pb-8 md:pb-20">
          <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 lg:px-16">
            <div className="text-left">
              <h1 className="text-2xl sm:text-3xl md:text-5xl font-bold mb-3 md:mb-6 font-helvetica">
                Empowering Enterprises to Thrive in a <span className="text-green-900">New Era</span> of Technology
              </h1>
              <p className="text-base sm:text-lg md:text-2xl leading-relaxed text-gray-700 max-w-full font-helvetica">
                At Robnett Technologies, our mission is simple yet profound: to transform how businesses harness technology...
              </p>
            </div>
          </div>
        </div>

        {/* Core Values Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mb-8 md:mb-20">
          <h2 className="text-2xl sm:text-3xl md:text-5xl font-bold text-center mb-6 md:mb-8 text-green-900 font-helvetica">
            Our Core Values
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>

        {/* Data Security Section */}
        <div className="max-w-3xl mx-auto px-4 text-center mb-8 md:mb-20">
          <div ref={iconRef} className="cursor-pointer">
            <RiShieldKeyholeLine className="mx-auto h-12 w-12 md:h-20 md:w-20 text-green-900 mb-4 md:mb-6" />
          </div>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight text-gray-800 font-helvetica mb-3 md:mb-4">
            Our Commitment to Data Security
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-6 md:mb-8 font-helvetica leading-relaxed">
            In a world where data breaches are commonplace, Robnett Technologies stands as a guardian of your information. Our commitment to data security is reflected in our state-of-the-art infrastructure, comprehensive security protocols, and ongoing monitoring. We believe that your data is your most valuable asset, and we are here to protect it with all our resources.
          </p>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-block"
          >
            <Link 
              to="/privacy-video"
              className="inline-flex items-center px-4 sm:px-6 py-2.5 sm:py-3 border border-transparent text-sm md:text-base font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out font-helvetica"
            >
              <FaPlay className="mr-2 h-3.5 w-3.5 md:h-4 md:w-4" /> WATCH VIDEO
            </Link>
          </motion.div>
        </div>

        {/* Call Section */}
        <div ref={containerRef} className="px-4 sm:px-6 py-8 md:py-6 bg-[#faf9f6] max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6 md:gap-8">
            <div className="flex flex-col text-center md:text-left">
              <h2 className="text-xl md:text-3xl font-bold leading-tight text-gray-800 font-helvetica mb-3 md:mb-4">
                <div className="mb-2 md:mb-1">TAKE CONTROL OF YOUR SECURE TRANSFORMATION</div>
                <div>SCALE YOUR BUSINESS WITH ROBNETT TECHNOLOGIES</div>
              </h2>
              <h1 
                ref={headlineRef} 
                className="text-2xl md:text-4xl font-bold text-green-900 font-helvetica mt-1 md:mt-2"
              ></h1>
            </div>
            <Link
              ref={buttonRef}
              to="/contact-us"
              className="bg-black text-white px-4 md:px-6 py-2 md:py-3 rounded-full font-bold text-sm md:text-base transition-colors duration-300 flex items-center shadow-md hover:shadow-lg font-helvetica whitespace-nowrap"
            >
              TALK TO OUR EXPERTS
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-3 w-3 md:h-4 md:w-4 ml-2" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                  clipRule="evenodd" 
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Footer */}
    
    </>
  );
};

export default OurMission;