import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(TextPlugin);

const Call = () => {
  const containerRef = useRef(null);
  const headlineRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Load Google Fonts
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // GSAP animations
    const tl = gsap.timeline();
    
    tl.fromTo(containerRef.current, 
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: 'power2.out' }
    )
    .fromTo(headlineRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.5, ease: 'power2.out' }
    )
    .to(headlineRef.current, {
      duration: 2,
      text: "Your Trusted Partner.",
      ease: "none",
    })
    .fromTo(buttonRef.current,
      { opacity: 0, x: 20 },
      { opacity: 1, x: 0, duration: 0.4, ease: 'power2.out' },
      '-=0.3'
    );

    // Button zoom animation
    const buttonZoom = gsap.timeline({ repeat: -1, yoyo: true });
    buttonZoom.to(buttonRef.current, {
      scale: 1.05,
      duration: 0.5,
      ease: 'power1.inOut'
    });

    // Button hover effect
    $(buttonRef.current).hover(
      function() { 
        gsap.to(this, { backgroundColor: '#333', duration: 0.3 });
        buttonZoom.pause();
        gsap.to(this, { scale: 1.1, duration: 0.3 });
      },
      function() { 
        gsap.to(this, { backgroundColor: '#000', duration: 0.3 });
        buttonZoom.resume();
        gsap.to(this, { scale: 1, duration: 0.3 });
      }
    );

    return () => {
      buttonZoom.kill();
    };

  }, []);

  return (
    <div 
      ref={containerRef} 
      className="call-container flex flex-col md:flex-row justify-between items-center px-4 md:px-6 py-8 md:py-6 bg-[#faf9f6] gap-6 md:gap-0"
    >
      <div className="flex flex-col text-center md:text-left">
        <h2 className="text-xl md:text-3xl font-bold leading-tight text-gray-800 font-montserrat mb-3 md:mb-4">
          <div className="mb-2 md:mb-1">TAKE CONTROL OF YOUR SECURE TRANSFORMATION</div>
          <div>SCALE YOUR BUSINESS WITH ROBNETT TECHNOLOGIES</div>
        </h2>
        <h1 
          ref={headlineRef} 
          className="text-2xl md:text-4xl font-bold text-green-900 font-montserrat mt-1 md:mt-2"
        ></h1>
      </div>
      <Link
        ref={buttonRef}
        to="/contact-us"
        className="bg-black text-white px-4 md:px-6 py-2 md:py-3 rounded-full font-bold text-sm md:text-base transition-colors duration-300 flex items-center shadow-md hover:shadow-lg font-montserrat whitespace-nowrap"
      >
        TALK TO OUR EXPERTS
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-3 w-3 md:h-4 md:w-4 ml-2" 
          viewBox="0 0 20 20" 
          fill="currentColor"
        >
          <path 
            fillRule="evenodd" 
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
            clipRule="evenodd" 
          />
        </svg>
      </Link>
    </div>
  );
};

export default Call;