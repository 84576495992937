import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import $ from 'jquery';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import { FaLinkedin, FaYoutube, FaTwitter, FaFacebook } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import logo from '../../assets/logo2.png';

// Import images
import image1a from '../../assets/proactive1.jpg';
import image1b from '../../assets/proactive2.jpg';
import image1c from '../../assets/proactive3.jpg';
import image2a from '../../assets/proactive4.jpg';
import image2b from '../../assets/proactive5.jpg';
import image2c from '../../assets/proactive6.jpg';
import image3a from '../../assets/proactive7.jpg';
import image3b from '../../assets/proactive8.jpg';
import image3c from '../../assets/proactive9.jpg';

import heroBackground from '../../assets/backup-solutions.jpg';
import customWebsiteImage from '../../assets/cloud1.jpg';
import ecommercePlatformImage from '../../assets/cloud2.jpg';
import cmsImage from '../../assets/cloud3.jpg';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const rotateIn = keyframes`
  0% { transform: rotateX(90deg); }
  100% { transform: rotateX(0deg); }
`;

const rotateOut = keyframes`
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(-90deg); }
`;

const RotatingTextWrapper = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
  height: auto;
  min-height: 47px;
  vertical-align: bottom;
  margin-left: 5px;
  font-size: 1.5rem;
  padding: 10px 0;
  
  @media (min-width: 640px) {
    font-size: 3rem;
    height: 47px;
    padding: 0;
  }
  font-family: Helvetica, Arial, sans-serif;

  p {
    display: inline-flex;
    margin: 0;
    vertical-align: top;
    flex-wrap: wrap;
    justify-content: center;
    
    @media (min-width: 640px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
`;

const Word = styled.span`
  position: relative;
  display: flex;
  opacity: 0;
  overflow: visible;
  white-space: nowrap;
  font-size: inherit;
  padding: 0 5px;
  
  @media (min-width: 640px) {
    position: absolute;
    padding: 0;
  }

  .letter {
    transform-origin: center center 25px;
    font-size: inherit;

    &.out {
      transform: rotateX(90deg);
      transition: 0.26s cubic-bezier(0.6, 0, 0.7, 0.2);
    }
    &.in {
      transition: 0.20s ease;
    }
    &.behind {
      transform: rotateX(-90deg);
    }
  }

  &.alizarin { color: #14532D; }
  &.wisteria { color: #3498db; }
  &.peter-river { color: #14532D; }
`;
const RotatingText = ({ words }) => {
  const wordsRef = useRef([]);

  useEffect(() => {
    let currentWordIndex = 0;
    const maxWordIndex = words.length - 1;

    const rotateText = () => {
      const currentWord = wordsRef.current[currentWordIndex];
      const nextWord = currentWordIndex === maxWordIndex ? wordsRef.current[0] : wordsRef.current[currentWordIndex + 1];

      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = "letter out";
        }, i * 80);
      });

      nextWord.style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = "letter behind";
        setTimeout(() => {
          letter.className = "letter in";
        }, 340 + i * 80);
      });

      currentWordIndex = currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    wordsRef.current[0].style.opacity = "1";
    rotateText();
    const intervalId = setInterval(rotateText, 4000);

    return () => clearInterval(intervalId);
  }, [words]);

  return (
    <RotatingTextWrapper>
      <p>
        {words.map((word, index) => (
          <Word
            key={index}
            ref={el => wordsRef.current[index] = el}
            className={`word ${word.color}`}
          >
            {word.text.split('').map((letter, i) => (
              <span key={i} className="letter">{letter}</span>
            ))}
          </Word>
        ))}
      </p>
    </RotatingTextWrapper>
  );
};

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative w-full h-48 sm:h-64 md:h-96 rounded-lg overflow-hidden">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index + 1}`}
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 ${
            index === currentIndex ? 'opacity-100' : 'opacity-0'
          }`}
        />
      ))}
    </div>
  );
};

const ServiceCard = ({ title, image, description }) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-lg mx-2 sm:mx-4 flex flex-col font-helvetica">
    <div className="relative h-40 sm:h-48">
      <img src={image} alt={title} className="w-full h-full object-cover" />
      <h3 className="absolute bottom-0 left-0 right-0 text-white text-lg sm:text-2xl font-bold p-3 sm:p-4 bg-gradient-to-t from-black/60 to-transparent">
        {title}
      </h3>
    </div>
    <div className="p-4 sm:p-6">
      <p className="text-gray-600 text-sm sm:text-base">{description}</p>
    </div>
  </div>
);
const BackupServices = () => {
  const sectionRef = useRef(null);
  const containerRef = useRef(null);
  const headlineRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(".service-card", { y: 100, opacity: 0 });

      ScrollTrigger.create({
        trigger: sectionRef.current,
        start: "top 80%",
        onEnter: () => {
          gsap.to(".service-card", {
            y: 0,
            opacity: 1,
            duration: 0.5,
            stagger: 0.1,
            ease: "power3.out"
          });
        },
        once: true
      });

      gsap.utils.toArray('.max-w-6xl > div').forEach((section, i) => {
        const direction = i % 2 === 0 ? 50 : -50;
        
        gsap.from(section, {
          x: direction,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            end: "top 20%",
            scrub: 1,
            once: true
          }
        });
      });

      const callTl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 80%',
        }
      });
      
      callTl.fromTo(containerRef.current, 
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 0.8, ease: 'power2.out' }
      )
      .fromTo(headlineRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power2.out' }
      )
      .to(headlineRef.current, {
        duration: 2,
        text: "Your Trusted Partner.",
        ease: "none",
      })
      .fromTo(buttonRef.current,
        { opacity: 0, x: 20 },
        { opacity: 1, x: 0, duration: 0.4, ease: 'power2.out' },
        '-=0.3'
      );

      const buttonZoom = gsap.timeline({ repeat: -1, yoyo: true });
      buttonZoom.to(buttonRef.current, {
        scale: 1.05,
        duration: 0.5,
        ease: 'power1.inOut'
      });

      $(buttonRef.current).hover(
        function() { 
          gsap.to(this, { backgroundColor: '#333', duration: 0.3 });
          buttonZoom.pause();
          gsap.to(this, { scale: 1.1, duration: 0.3 });
        },
        function() { 
          gsap.to(this, { backgroundColor: '#000', duration: 0.3 });
          buttonZoom.resume();
          gsap.to(this, { scale: 1, duration: 0.3 });
        }
      );
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const rotatingWords = [
    { text: "Protected", color: "alizarin" },
    { text: "Available", color: "wisteria" },
    { text: "Resilient", color: "peter-river" },
  ];

  return (
    <>
      {/* Hero Section with Mobile Optimizations */}
      <div className="relative min-h-[500px] sm:h-screen flex items-center text-white font-helvetica">
  <div className="absolute inset-0 z-0">
    <img 
      src={heroBackground} 
      alt="AI and ML Background" 
      className="w-full h-full object-cover object-center" 
    />
    <div className="absolute inset-0 bg-black opacity-50"></div>
  </div>
  <div className="relative z-10 px-4 sm:px-10 max-w-4xl mx-4 sm:ml-8 md:ml-16 lg:ml-24 mt-16 sm:mt-24 lg:mt-32">
    <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight">
      Robnett: Secure Your Future with Bulletproof
    </h1>
    <h2 className="text-xl sm:text-3xl md:text-4xl font-semibold leading-relaxed">
      Backup Strategies
    </h2>
  </div>
</div>

      <div ref={sectionRef} className="w-full font-helvetica bg-[#faf9f6]">
        {/* Main Content Section */}
        <div className="bg-[#faf9f6] pt-8 sm:pt-16 pb-8 sm:pb-20">
  <div className="max-w-full mx-auto px-4 sm:px-8 lg:px-16">
    <div className="text-center sm:text-left">
      <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4">
        <span className="inline">Ensuring Your Data Is Always:</span>
        <div className="flex flex-col sm:flex-row items-center sm:items-start">
          <div className="inline-block">
            <RotatingText words={rotatingWords} />
          </div>
        </div>
      </h1>
      <p className="text-1xl sm:text-2xl leading-relaxed text-gray-700 max-w-full px-4 sm:px-0">
  At Robnett Technologies, we understand that your data is your most valuable asset, and keeping it safe is our top priority. Our backup solutions are designed to give you peace of mind, knowing that no matter what happens, your data is secure, recoverable, and protected from any potential threats.
</p>
            </div>
          </div>
        </div>
                {/* Core Services Section */}
                <div className="max-w-7xl mx-auto mt-6 sm:mt-10 mb-10 sm:mb-20 px-4 sm:px-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {[
              {
                title: 'CLOUD BACKUP',
                image: customWebsiteImage,
                description: "Our Cloud Backup Solutions offer secure, scalable storage, ensuring your data is safely backed up and accessible from any location. With advanced encryption and seamless integration, you can trust that your data remains protected in the cloud."
              },
              {
                title: 'SERVER BACKUP',
                image: ecommercePlatformImage,
                description: "Robnett's Server Backup Solutions provide automated, reliable backups for your essential data. Designed to protect mission-critical information, our solutions offer constant monitoring and rapid recovery, so your operations run without interruption."
              },
              {
                title: 'CUSTOMIZED BACKUP',
                image: cmsImage,
                description: "Every business is unique, and so are its backup needs. Our Customized Backup Solutions are tailored to meet your specific data requirements, offering flexible options and a high level of security that aligns with your operational demands."
              }
            ].map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>

        {/* New Headline Section */}
        <div className="bg-[#faf9f6] py-8 sm:py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <h2 className="text-2xl sm:text-4xl font-bold text-center mb-4">
              Promising Continuity: Safeguarding Your <span className="text-green-900">DATA</span> for Tomorrow!
            </h2>
          </div>
        </div>

        {/* Dynamic Sections */}
       
      {/* Dynamic Sections with matching Cloud page styling */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-12 sm:mb-20">
          {[
            {
              title: "Proactive Security & Data Integrity",
              description: "At Robnett, we prioritize your data's security and integrity. Our proactive approach means that we are constantly monitoring your systems for any vulnerabilities. With advanced security protocols in place, we ensure that your data remains safe from unauthorized access or corruption. We believe that your data deserves the highest level of protection, and we are committed to delivering that peace of mind.",
              images: [image1a, image1b, image1c],
              reverse: false
            },
            {
              title: "Business Continuity & Rapid Recovery ",
              description: "In today's fast-paced business environment, downtime can be costly. That's why our backup solutions are designed to ensure your business can recover swiftly from any data loss incidents. Whether due to hardware failures or accidental deletions, we have your back. Our rapid recovery processes allow you to get back to business in no time, ensuring that you can focus on what you do best without the worry of data loss.",
              images: [image2a, image2b, image2c],
              reverse: true
            },
            {
              title: "Compliance-Centric Backup Solutions",
              description: "Data compliance is crucial for every business, and at Robnett, we take it seriously. Our backup solutions are designed to help you adhere to industry standards and regulations, ensuring that your data practices are compliant with legal requirements. We understand the importance of safeguarding your data not just from threats, but also ensuring that it meets the necessary compliance benchmarks, providing you with the confidence you need in your data management practices.",
              images: [image3a, image3b, image3c],
              reverse: false
            },
          ].map((section, index) => (
            <div key={index} className={`flex flex-col ${section.reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center mb-12 sm:mb-20 last:mb-0`}>
              <div className={`w-full md:w-1/2 ${section.reverse ? 'md:pl-8' : 'md:pr-8'} mb-8 md:mb-0`}>
                <h2 className="text-2xl sm:text-3xl font-bold mb-4">{section.title}</h2>
                <p className="text-base sm:text-lg text-gray-700">{section.description}</p>
              </div>
              <div className="w-full md:w-1/2">
                <ImageSlider images={section.images} />
              </div>
            </div>
          ))}
        </div>


        {/* Data Security Card */}
        <div className="max-w-6xl mx-auto mb-10 sm:mb-20 px-4 sm:px-6">
          <div className="bg-white rounded-lg shadow-lg p-4 sm:p-8 animated-section">
            <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4 text-center">
              Your Data is Always <span className="text-green-900">Secure</span>—No Matter the Circumstance
            </h2>
            <p className="text-base sm:text-xl text-gray-700 text-center">
              Robnett Technologies constantly elevates data security through our advanced backup solutions, making sure that your business stays protected, even in the face of adversity.
            </p>
          </div>
        </div>

        {/* Call to Action */}
        <div ref={containerRef} className="px-4 sm:px-6 py-6 bg-[#faf9f6] max-w-7xl mx-auto">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-6">
            <div className="text-center sm:text-left">
              <h2 className="text-xl sm:text-3xl font-bold leading-tight text-gray-800 mb-4">
                <div className="mb-2">TAKE CONTROL OF YOUR CLOUD SECURITY</div>
                <div>SCALE YOUR INFRASTRUCTURE WITH ROBNETT TODAY!!!</div>
              </h2>
              <h1 ref={headlineRef} className="text-2xl sm:text-4xl font-bold text-[#14532D] mt-2"></h1>
            </div>
            <Link
              ref={buttonRef}
              to="/contact-us"
              className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-bold text-sm sm:text-base transition-colors duration-300 flex items-center shadow-md hover:shadow-lg whitespace-nowrap"
            >
              TALK TO OUR EXPERTS
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-4 sm:w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      {/* Footer with Mobile Optimizations */}
     
     
    </>
  );
};

export default BackupServices;