
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import $ from 'jquery';
import { FaLinkedin, FaYoutube, FaTwitter, FaFacebook } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import logo from '../../assets/logo2.png';
import heroBackground from '../../assets/cloud-solution1.jpg';
import customWebsiteImage from '../../assets/toc.jpg';
import ecommercePlatformImage from '../../assets/Marg.jpg';
import cmsImage from '../../assets/busy.jpg';
import image1a from '../../assets/ai.jpg';
import image1b from '../../assets/ai2.jpg';
import image1c from '../../assets/ai3.jpg';
import image2a from '../../assets/Trust1.jpg';
import image2b from '../../assets/Trust2.jpg';
import image2c from '../../assets/Trust3.jpg';
import image3a from '../../assets/rans1.jpg';
import image3b from '../../assets/rans2.jpg';
import image3c from '../../assets/rans3.jpg';
import image4a from '../../assets/advance.jpg';
import image4b from '../../assets/advance2.jpg';
import image4c from '../../assets/advance3.jpg';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const StatCard = React.forwardRef(({ value, label, suffix = "", countDown = false }, ref) => (
  <div ref={ref} className="bg-gradient-to-br from-blue-500 to-green-500 rounded-lg shadow-lg p-4 sm:p-6 text-center transform transition-all duration-300 hover:scale-105">
    <h2 className="text-3xl sm:text-5xl font-bold text-[#E6F7FF] mb-2 font-helvetica">
      <span className="count-up" data-value={value} data-countdown={countDown}>0</span>{suffix}
    </h2>
    <p className="text-base sm:text-lg text-[#E6F7FF] font-helvetica">{label}</p>
  </div>
));

const rotateIn = keyframes`
  0% { transform: rotateX(90deg); }
  100% { transform: rotateX(0deg); }
`;

const rotateOut = keyframes`
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(-90deg); }
`;

const RotatingTextWrapper = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
  height: auto;
  min-height: 47px;
  vertical-align: bottom;
  margin-left: 5px;
  font-size: 1.5rem;
  padding: 10px 0;
  
  @media (min-width: 640px) {
    font-size: 3rem;
    height: 47px;
    padding: 0;
  }
  font-family: Helvetica, Arial, sans-serif;

  p {
    display: inline-flex;
    margin: 0;
    vertical-align: top;
    flex-wrap: wrap;
    justify-content: center;
    
    @media (min-width: 640px) {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
`;

const Word = styled.span`
  position: relative;
  display: flex;
  opacity: 0;
  overflow: visible;
  white-space: nowrap;
  font-size: inherit;
  padding: 0 5px;
  
  @media (min-width: 640px) {
    position: absolute;
    padding: 0;
  }

  .letter {
    transform-origin: center center 25px;
    font-size: inherit;

    &.out {
      transform: rotateX(90deg);
      transition: 0.26s cubic-bezier(0.6, 0, 0.7, 0.2);
    }
    &.in {
      transition: 0.20s ease;
    }
    &.behind {
      transform: rotateX(-90deg);
    }
  }

  &.alizarin { color: #14532D; }
  &.wisteria { color: #3498db; }
  &.peter-river { color: #14532D; }
`;
const RotatingText = ({ words }) => {
  const wordsRef = useRef([]);

  useEffect(() => {
    let currentWordIndex = 0;
    const maxWordIndex = words.length - 1;

    const rotateText = () => {
      const currentWord = wordsRef.current[currentWordIndex];
      const nextWord = currentWordIndex === maxWordIndex ? wordsRef.current[0] : wordsRef.current[currentWordIndex + 1];

      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = "letter out";
        }, i * 80);
      });

      nextWord.style.opacity = "1";
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = "letter behind";
        setTimeout(() => {
          letter.className = "letter in";
        }, 340 + i * 80);
      });

      currentWordIndex = currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    wordsRef.current[0].style.opacity = "1";
    rotateText();
    const intervalId = setInterval(rotateText, 4000);

    return () => clearInterval(intervalId);
  }, [words]);

  return (
    <RotatingTextWrapper>
      <p>
        {words.map((word, index) => (
          <Word
            key={index}
            ref={el => wordsRef.current[index] = el}
            className={`word ${word.color}`}
          >
            {word.text.split('').map((letter, i) => (
              <span key={i} className="letter">{letter}</span>
            ))}
          </Word>
        ))}
      </p>
    </RotatingTextWrapper>
  );
};

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative w-full h-64 sm:h-96 rounded-lg overflow-hidden">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index + 1}`}
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 ${
            index === currentIndex ? 'opacity-100' : 'opacity-0'
          }`}
        />
      ))}
    </div>
  );
};
const CloudSolutions = () => {
  const sectionRef = useRef(null);
  const containerRef = useRef(null);
  const headlineRef = useRef(null);
  const buttonRef = useRef(null);
  const cardsRef = useRef([]);

  const rotatingWords = [
    { text: "Security", color: "alizarin" },
    { text: "Reliability", color: "wisteria" },
    { text: "Transparency", color: "peter-river" },
  ];

  useEffect(() => {
    const ctx = gsap.context(() => {
      // Cards animation
      gsap.set(".service-card", { y: 100, opacity: 0 });

      ScrollTrigger.create({
        trigger: sectionRef.current,
        start: "top 80%",
        onEnter: () => {
          gsap.to(".service-card", {
            y: 0,
            opacity: 1,
            duration: 0.5,
            stagger: 0.1,
            ease: "power3.out"
          });
        },
        once: true
      });

      // Call section animations
      const callTl = gsap.timeline();
      
      callTl.fromTo(containerRef.current, 
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power2.out' }
      )
      .fromTo(headlineRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: 'power2.out' }
      )
      .to(headlineRef.current, {
        duration: 2,
        text: "Your Trusted Partner.",
        ease: "none",
      })
      .fromTo(buttonRef.current,
        { opacity: 0, x: 20 },
        { opacity: 1, x: 0, duration: 0.4, ease: 'power2.out' },
        '-=0.3'
      );

      // Button zoom animation
      const buttonZoom = gsap.timeline({ repeat: -1, yoyo: true });
      buttonZoom.to(buttonRef.current, {
        scale: 1.05,
        duration: 0.5,
        ease: 'power1.inOut'
      });

      // Button hover effect
      $(buttonRef.current).hover(
        function() { 
          gsap.to(this, { backgroundColor: '#333', duration: 0.3 });
          buttonZoom.pause();
          gsap.to(this, { scale: 1.1, duration: 0.3 });
        },
        function() { 
          gsap.to(this, { backgroundColor: '#000', duration: 0.3 });
          buttonZoom.resume();
          gsap.to(this, { scale: 1, duration: 0.3 });
        }
      );

      // Stat cards animation
      const startCounting = (index) => {
        const target = cardsRef.current[index].querySelector('.count-up');
        const value = parseFloat(target.getAttribute('data-value'));
        const countDown = target.getAttribute('data-countdown') === 'true';
        
        gsap.fromTo(target, 
          { innerText: countDown ? 100 : 0 },
          {
            innerText: value,
            duration: 1.5,
            ease: "power1.inOut",
            snap: { innerText: 1 },
            stagger: 1,
          }
        );
      };

      ScrollTrigger.create({
        trigger: ".ratings-section",
        start: "top 80%",
        onEnter: () => {
          cardsRef.current.forEach((_, index) => startCounting(index));
        },
        once: true
      });

      // Alternating sections animation
      gsap.utils.toArray('.max-w-6xl > div').forEach((section, i) => {
        const direction = i % 2 === 0 ? 50 : -50;
        
        gsap.from(section, {
          x: direction,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
            end: "top 20%",
            scrub: 1,
            once: true
          }
        });
      });

      // Hero text animation
      gsap.from(".hero-text", {
        y: 50,
        opacity: 0,
        duration: 1,
        stagger: 0.2,
        ease: "power2.out"
      });

    }, sectionRef);

    return () => ctx.revert();
  }, []);

  return (
    <>
      {/* Hero Section */}
      <div className="relative min-h-[80vh] md:min-h-screen flex items-center text-white font-helvetica">
  <div className="absolute inset-0 z-0">
    <img 
      src={heroBackground} 
      alt="AI and ML Background" 
      className="w-full h-full object-cover object-center" 
    />
    <div className="absolute inset-0 bg-black opacity-50"></div>
  </div>
  <div className="relative z-10 px-4 sm:px-10 max-w-4xl mx-auto md:ml-16 lg:ml-24 mt-16 md:mt-24">
    <h1 className="text-3xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight">
      Robnett: Unleashing Cloud Capabilities
    </h1>
    <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-relaxed">
      For Boundless Growth
    </h2>
  </div>
</div>

      <div ref={sectionRef} className="w-full font-helvetica bg-[#faf9f6]">
  {/* Mission Statement Section */}
  <div className="bg-[#faf9f6] pt-8 sm:pt-16 pb-8 sm:pb-20">
  <div className="max-w-full mx-auto px-4 sm:px-8 lg:px-16">
    <div className="text-center sm:text-left">
      <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold mb-4">
        <span className="inline">Robnett's Cloud Solutions: Built for</span>
        <div className="flex flex-col sm:flex-row items-center sm:items-start">
          <div className="inline-block">
            <RotatingText words={rotatingWords} />
          </div>
        </div>
      </h1>
        <p className="text-base sm:text-xl md:text-2xl leading-relaxed text-gray-700 max-w-full mt-6 px-2 sm:px-0">
          <span className="block mb-3">At Robnett Technologies, we're breaking the wheel of traditional technology with secure cloud solutions.</span>
          <span className="block">Our cloud services ensure that your essential business applications like Tally, Busy, and Marg are accessible anytime, anywhere, with the highest standards of data protection.</span>
        </p>
      </div>
    </div>
  </div>

        {/* Core Services Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mb-12 sm:mb-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
            {[
              {
                title: 'Tally On Cloud',
                image: customWebsiteImage,
                description: "Experience seamless Tally operations with our cloud infrastructure, ensuring your business never stops."
              },
              {
                title: 'Marg On Cloud',
                image: ecommercePlatformImage,
                description: "Access Marg anywhere, anytime with our secure cloud hosting solutions."
              },
              {
                title: 'Busy On Cloud',
                image: cmsImage,
                description: "Transform your Busy software experience with our reliable cloud infrastructure."
              }
            ].map((service, index) => (
              <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg mx-auto w-full max-w-sm sm:max-w-none service-card">
                <div className="relative h-48 sm:h-56">
                  <img src={service.image} alt={service.title} className="w-full h-full object-cover" />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent">
                    <h3 className="text-white text-xl sm:text-2xl font-bold p-4">{service.title}</h3>
                  </div>
                </div>
                <div className="p-4 sm:p-6">
                  <p className="text-sm sm:text-base text-gray-700">{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Security Stats Section */}
        <div className="ratings-section bg-[#faf9f6] p-4 sm:p-8 overflow-hidden mb-12 sm:mb-20">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
              <StatCard ref={el => cardsRef.current[0] = el} value={99.9} label="Uptime" suffix="%" />
              <StatCard ref={el => cardsRef.current[1] = el} value={0} label="Security Breaches" countDown={true} />
              <StatCard ref={el => cardsRef.current[2] = el} value={2500} label="TB Data Secured" suffix="+" />
              <StatCard ref={el => cardsRef.current[3] = el} value={100} label="Security Audits" suffix="+" />
            </div>
          </div>
        </div>

        {/* Alternating Sections */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-12 sm:mb-20">
          {[
            {
              title: "AI-Driven Security",
              description: "At Robnett Technologies, we leverage AI to enhance our cloud security measures, ensuring proactive threat detection and response. Our AI-driven solutions analyze data patterns in real time, identifying potential vulnerabilities and addressing them before they become issues. ",
              images: [image1a, image1b, image1c],
              reverse: false
            },
            {
              title: "Zero-Trust Architecture",
              description: "With our Zero Trust Architecture, security is not just a feature; it's embedded into every layer of our cloud services. We operate on the principle of “never trust, always verify,” ensuring that access is continuously authenticated and monitored. This comprehensive approach minimizes risks and strengthens your overall security posture, providing peace of mind for businesses that prioritize data integrity.",
              images: [image2a, image2b, image2c],
              reverse: true
            },
            {
              title: "Ransomware-Protection",
              description: "Robnett Technologies offers robust ransomware protection built into our cloud infrastructure. Through continuous data backups, rapid response protocols, and secure storage solutions, we help businesses mitigate the impact of ransomware attacks. Our multi-layered protection strategy includes real-time monitoring and quick data recovery, so your operations remain resilient even in the face of sophisticated cyber threats.",
              images: [image3a, image3b, image3c],
              reverse: false
            },
            {
              title: "Advanced Data Encryption",
              description: "Protecting your data is our top priority. Robnett’s cloud solutions implement advanced encryption methods that secure your data both at rest and in transit. By employing state-of-the-art encryption protocols, we ensure that even in the unlikely event of an unauthorized access attempt, your data remains unreadable and safe.",
              images: [image4a, image4b, image4c],
              reverse: true
            }
          ].map((section, index) => (
            <div key={index} className={`flex flex-col ${section.reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center mb-12 sm:mb-20 last:mb-0`}>
              <div className={`w-full md:w-1/2 ${section.reverse ? 'md:pl-8' : 'md:pr-8'} mb-8 md:mb-0`}>
                <h2 className="text-2xl sm:text-3xl font-bold mb-4">{section.title}</h2>
                <p className="text-base sm:text-lg text-gray-700">{section.description}</p>
              </div>
              <div className="w-full md:w-1/2">
                <ImageSlider images={section.images} />
              </div>
            </div>
          ))}
        </div>

        {/* Call Section */}
        <div ref={containerRef} className="px-4 sm:px-6 py-6 bg-[#faf9f6] max-w-7xl mx-auto">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-6">
            <div className="text-center sm:text-left">
              <h2 className="text-xl sm:text-3xl font-bold leading-tight text-gray-800 mb-4">
                <div className="mb-2">TAKE CONTROL OF YOUR CLOUD SECURITY</div>
                <div>SCALE YOUR INFRASTRUCTURE WITH ROBNETT TODAY!!!</div>
              </h2>
              <h1 ref={headlineRef} className="text-2xl sm:text-4xl font-bold text-[#14532D] mt-2"></h1>
            </div>
            <Link
              ref={buttonRef}
              to="/contact-us"
              className="bg-black text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-bold text-sm sm:text-base transition-colors duration-300 flex items-center shadow-md hover:shadow-lg whitespace-nowrap"
            >
              TALK TO OUR EXPERTS
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-4 sm:w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </div>

      {/* Footer */}
     
    </>
  );
};

export default CloudSolutions;